/* eslint jsx-a11y/click-events-have-key-events: 0 jsx-a11y/no-static-element-interactions: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './popup.module.scss';
import Cross from '../../assets/cross.svg';

class Popup extends Component {
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
    this.state = {
      hidden: true,
    };
  }

  componentDidMount() {
    const { hidden, mountDelay } = this.props;
    if (!hidden) {
      setTimeout(() => {
        this.setState({ hidden: false });
      }, mountDelay);
    }
  }

  componentDidUpdate({ hidden: prevHidden }) {
    const { hidden } = this.props;
    if (prevHidden !== hidden) {
      this.setState({ hidden });
    }
  }

  onClose(e) {
    e.preventDefault();
    const { closeable, close } = this.props;
    if (!closeable) return;
    this.setState({ hidden: true });
    if (!close) return;
    setTimeout(() => {
      close();
    }, 300);
  }

  render() {
    const {
      style, children, modal, className, size, closeable, title, confirmText, onConfirm,
      popupContentStyle,
    } = this.props;
    const { hidden } = this.state;

    // dermine if close func should be passed to child.
    const isClassComponent = typeof(children) === 'function' && !!children.prototype.isReactComponent;

    return (
      <span className={cx({ [styles.hidden]: hidden })}>

        { modal && <div className={cx(styles.modal)} onClick={this.onClose} /> }
        
        <div className={cx(styles.popup, styles[size], className)} style={style}>
          { closeable && (
            <button
              type="button"
              className={cx(styles.closeX)}
              onClick={this.onClose}
              onKeyDown={this.onClose}
              tabIndex={0}
            ><Cross />
            </button>
          )}

          { title && (
            <div className={cx(styles.popupTitle)}>
              <h3>{ title }</h3>
            </div>
          )}

          <div
            className={cx(styles.popupContent, {
            [styles.hasTitle]: title,
            [styles.hasActions]: onConfirm,
          })}
            style={popupContentStyle}
          >
            { isClassComponent ? React.cloneElement(children, {
              close: this.onClose
            }) : children }
          </div>

          { onConfirm && (
            <div className={cx(styles.popupActions)}>
              <button
                type="button"
                className={cx('btn', 'btn-grey', 'btn-sm')}
                onClick={this.onClose}
              >Close
              </button>
              <button
                type="submit"
                className={cx('btn', 'btn-sm')}
                onClick={this.onConfirm}
              >{confirmText}
              </button>
            </div>
          )}

        </div>
      </span>
    );
  }
}

Popup.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  /* if no close function is passed in, the popup can only be shown and hidden once. */
  close: PropTypes.func,
  closeable: PropTypes.bool,
  /* popup visibility can be toggled using the 'hidden' prop or simply by mounting/unmounting the component. */
  hidden: PropTypes.bool,
  modal: PropTypes.bool,
  /* milliseconds to delay the popup visibility. useful for graceful entry after a page loads. */
  mountDelay: PropTypes.number,
  size: PropTypes.oneOf(['sm', 'md', 'l', 'xl', 'max', 'fullScreen', 'xm']),
  style: PropTypes.shape({}),
  popupContentStyle: PropTypes.shape({}),
  title: PropTypes.string,

  onConfirm: PropTypes.func,
  confirmText: PropTypes.string,
};

Popup.defaultProps = {
  className: '',
  close: null,
  closeable: true,
  hidden: false,
  modal: true,
  mountDelay: 0,
  size: 'md',
  style: {},
  popupContentStyle: {},
  title: null,

  onConfirm: null,
  confirmText: 'Confirm',
};

export default Popup;
