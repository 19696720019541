import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { compose, withState, withStateHandlers } from 'recompose';
import Popup from './Popup';

const PopupTrigger = ({
  setHidden,
  hidden,
  Trigger,
  popupProps,
  portalId = '___gatsby',
  children,
}) => (
  <span>
    {React.cloneElement(Trigger, {
      onClick: () => setHidden(false)
    })}

    { (!hidden && typeof(document) !== 'undefined')
      && (
        ReactDOM.createPortal(
          <Popup close={() => setHidden(true)} {...popupProps}>
            { children }
          </Popup>,
          document.getElementById(portalId),
        ))
      }
  </span>
);

PopupTrigger.propTypes = {
  Trigger: PropTypes.element.isRequired
}

PopupTrigger.defaultProps = {
}

const freezePage = (popupHidden) => {
  if (typeof(document) !== 'undefined') {
    const method = popupHidden ? 'remove' : 'add';
    document.documentElement.classList[method]('freeze');
  }
}

export default compose(
  withStateHandlers(
    ({ hidden = true }) => {
      freezePage(hidden);
      return { hidden };
    }, {
      setHidden: () => (hidden) => {
        freezePage(hidden);
        return { hidden };
      }
    }
  )
)(PopupTrigger);