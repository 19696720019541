import _ from 'lodash';
import cx from "classnames";
import React, { useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes, { instanceOf } from 'prop-types';
import { CookiesProvider, withCookies, Cookies } from 'react-cookie';
import { ThemeProvider } from '@material-ui/core';
import { resourceTheme } from '../styles/config';
import Helmet from './SEO';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { parseUrlParams } from '../utils';
import '../styles/main.scss'
import styles from './Layout.module.scss';
import settings from '../settings'

const Layout = ({
  children,
  cookies,
  showHeader=true,
  showFooter=true,
  disableContainer=false,
  excludeOffsetWhitespace=false,
  forceScrolling=false,
}) =>  {
  useEffect(() => {
    const searchParams = parseUrlParams();
    if (searchParams) {
      _.each(searchParams, (value, key) => {
        cookies.set(key, value);
      })
    }
  }, []);
  return (
    <StaticQuery 
      query={graphql`
        query headerAndSiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
          headerMarkdown: markdownRemark(frontmatter: { name: { eq: "header" } }) {
            frontmatter {
              bannerConfig {
                showBanner
                primaryText
                linkText
                linkUrl
                secondaryText
              }
              links {	              
                title	
                href	
              }	
              mobileLinks {	
                title	
                href	
              }
              guideLinks {
                title	
                href	
              }
              guideMobileLinks {
                title
                href
              }
            }	
          }	
          footerMarkdown: markdownRemark(frontmatter: { name: { eq: "footer" } }) {	
            frontmatter {	
              ctaTitle	
              ctaSubtitle	
              showSubCTA	
              notifySubtitle	
              notifyCTA	
              links {	
                title	
                href	
              }
              guideCtaTitle
              guideCtaSubtitle
              guideShowSubCTA
              guideNotifySubtitle
              guideNotifyCTA
              guideLinks {
                title
                href
              }
            }
          }
          seoOptions: markdownRemark(frontmatter: { name: { eq: "seo" } }) {
            frontmatter {
              title
              description
              shortTitle
              titleAlt
              siteLogo
              guideTitle
              guideDescription
              guideShortTitle
              guideTitleAlt
              guideSiteLogo
            }
          }
        }
      `}
      render={(data) => (
        <ThemeProvider theme={resourceTheme}>
          <div className="Page">
            <Helmet options={data.seoOptions.frontmatter} disableAnalytics />
            {
              showHeader && (
                <>
                  <Header
                    forceScrolling={forceScrolling}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...data.headerMarkdown.frontmatter}
                  />
                  { !excludeOffsetWhitespace && <div className="divForBannerOffset" />}
                </>
              )
            }
            <div className={disableContainer ? "" : "Content"}>
              {children}
            </div>
            {
              showFooter && (
                <Footer
                  className={cx([
                    ...settings.IsInterviewGuide ? ['section', 'fp-auto-height', styles.footer] : []
                  ])}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...data.footerMarkdown.frontmatter}
                />
              )
            }
          </div>
        </ThemeProvider>
      )}
    />
  )
};

Layout.propTypes = {
  children: PropTypes.func.isRequired,
  cookies: instanceOf(Cookies).isRequired,
}

const LayoutWithCookies = withCookies(Layout);

export default (props) => (
  <CookiesProvider>
    <LayoutWithCookies
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  </CookiesProvider>
)

