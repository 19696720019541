import { createMuiTheme } from '@material-ui/core/styles';
import Color from 'color';

export const resourceMidnightBlue = '#1F1440';
export const resourceMidnightBlue200 = '#A5A1B3';

export const resourceRoyalPurple = '#7348FF';
export const resourceCrystalBlue = '#38E4FF';
export const resourceRed = '#FF2E57';
export const resourceGrey = '#EFEEF1';
export const resourceWhite = '#FFFFFF';
export const resourceOrange = '#FF7227';
export const resourceError = '#F33E22';
export const resourceSuccessGreen = '#28a745';

export const resourcePrimaryFont = 'Circular';

export const themeCreator= ({
  primaryColorHex,
  secondaryColorHex,
}) => {
  const generalHeaderStyles = {
    fontFamily: resourcePrimaryFont,
    fontStyle: 'normal',
    color: resourceMidnightBlue,
    margin: '0px 0px 5px 0px',
  };

  const cardElevationStyles = {
    elevation1: {
      boxShadow: 'none',
      border: '1px solid #E3E2E5',
      borderRadius: '4px',
    },
    elevation2: {
      boxShadow: '0px 4px 15px rgba(157, 157, 157, 0.25)',
      border: '1px solid #E3E2E5',
    },
  };

  return createMuiTheme({
    palette: {
      primary: {
        main: primaryColorHex,
      },
      secondary: {
        main: secondaryColorHex,
      },
      warning: {
        main: resourceOrange,
      },
      error: {
        main: resourceError,
      },
    },
    typography: {
      fontFamily: resourcePrimaryFont,
      h1: {
        ...generalHeaderStyles,
        fontWeight: 'bold',
        fontSize: '64px',
        lineHeight: '68px',
        letterSpacing: '-1px',
      },
      h2: {
        ...generalHeaderStyles,
        fontWeight: 'bold',
        fontSize: '52px',
        lineHeight: '58px',
        letterSpacing: '-0.8px',
      },
      h3: {
        ...generalHeaderStyles,
        fontWeight: 'bold',
        fontSize: '36px',
        lineHeight: '40px',
        letterSpacing: '-0.4px',
      },
      h4: {
        ...generalHeaderStyles,
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '28px',
        letterSpacing: '-0.2px',
      },
      h5: {
        ...generalHeaderStyles,
        fontWeight: 900,
        fontSize: '18px',
        lineHeight: '20px',
      },
      h6: {
        ...generalHeaderStyles,
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '18px',
      },
      body1: {
        fontFamily: resourcePrimaryFont,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
      },
      // Body Small
      subtitle1: {
        fontFamily: resourcePrimaryFont,
        fontSize: '13px',
        lineHeight: '18px',
      },
      // Body Small ~ gray color
      subtitle2: {
        fontFamily: resourcePrimaryFont,
        fontSize: '13px',
        lineHeight: '18px',
        color: '#C4C4C4',
      },
      caption: {
        fontFamily: 'Circular',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '13px',
      },
    },
    props: {
      MuiStepper: {
        alternativeLabel: true,
      },
      MuiStepButton: {
        disableRipple: true,
      },
      MuiPaper: {
        elevation: 2,
      },
      MuiCard: {
        elevation: 2,
      },
      MuiTabs: {
        indicatorColor: 'primary',
      },
      MuiTab: {
        disableRipple: true,
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [{
            fontFamily: 'Circular',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 400,
            src: `
            local('Circular'),
          `,
            unicodeRange:
            'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
          }],
        },
      },
      MuiButton: {
        root: {
          borderRadius: '22px',
          padding: '5px 15px',
          textTransform: 'none',
        },
      },
      MuiTab: {
        root: {
          textTransform: 'none',
          fontFamily: resourcePrimaryFont,
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: '24px !important',
        },
      },
      MuiChip: {
        outlined: {
          border: '1px solid rgba(0, 0, 0, 0.15)',
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: '#ffffff',
          color: '#5A5A5A',
          border: '1px solid #E2E2E2',
          borderRadius: '4px',
          fontSize: '12px;',
          textAlign: 'center',
          maxWidth: '120px',
        },
        tooltipPlacementBottom: {
          marginTop: '2px',
        },
      },
      // Expansion Panels
      MuiExpansionPanel: {
        root: {
          transition: 'box-shadow 500ms',
          ...cardElevationStyles.elevation1,
          '&:not(:last-child)': {
            marginBottom: '10px',
          },
          '&:before': {
            backgroundColor: 'transparent',
          },
          '&$expanded': {
            margin: '10px 0',
            ...cardElevationStyles.elevation2,
          },
          '&$disabled': {
            backgroundColor: resourceWhite,
          },
        },
        rounded: {
          borderRadius: '4px',
        },
      },
      MuiExpansionPanelSummary: {
        root: {
          '&$disabled': {
            opacity: 1,
            color: resourceMidnightBlue200,
          },
          '&.Mui-focused': {
            background: resourceWhite,
          },
        },
      },
      // Paper
      MuiPaper: {
        elevation1: {
          ...cardElevationStyles.elevation1,
        },
        elevation2: {
          ...cardElevationStyles.elevation2,
        },
      },
      MuiCardMedia: {
        root: {
          margin: '15px',
        },
      },
      // Icon Button
      MuiIconButton: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      // Stepper
      MuiStepper: {
        root: {
          paddingLeft: '0px',
          paddingRight: '0px',
        },
      },
      MuiStepConnector: {
        root: {
          backgroundColor: primaryColorHex,
          opacity: '.2',
        },
        alternativeLabel: {
          top: '5.5px',
          left: 'calc(-50%)',
          right: 'calc(50%)',
          position: 'absolute',
          width: '100%',
          zIndex: 0,
        },
        active: {
          opacity: '1',
        },
        completed: {
          opacity: '1',
        },
        line: {
          height: 5,
          border: '0',
        },
        lineHorizontal: {
          borderTopWidth: '0',
        },
      },
      MuiStepIcon: {
        root: {
          height: '16px',
          width: '16px',
          fill: primaryColorHex,
          opacity: '.2',
          boxSizing: 'border-box',
        },
        active: {
          opacity: '1',
          border: `
          1px solid ${
  Color(primaryColorHex).isLight()
    ? Color(primaryColorHex).darken(0.20).hex().toString()
    : Color(primaryColorHex).darken(0.60).hex().toString()
}
          `,
          borderRadius: '50%',
        },
        completed: {
          opacity: '1',
          background: primaryColorHex,
          borderRadius: '50%',
        },
        text: {
          color: 'rgba(0, 0, 0, 0)',
          fill: 'none',
        },
      },
      MuiStepLabel: {
        root: {
          fontFamily: resourcePrimaryFont,
          fontSize: '13px',
          lineHeight: '18px',
          textAlign: 'center',
          '&$active': {
            fontWeight: 'bold',
          },
          '&:hover $iconContainer': {
            boxShadow: `
            0px 1px 8px
${Color(primaryColorHex).isLight()
    ? `rgba(${Color(primaryColorHex).darken(0.3).rgb().array()
      .join(', ')}, 0.5)`
    : `rgba(${Color(primaryColorHex).rgb().array().join(', ')}, 0.5)`
}`,
          },
        },
        iconContainer: {
          background: 'white',
          borderRadius: '50%',
          zIndex: 100,
        },
        label: {
          marginTop: '3px !important',
          fontFamily: resourcePrimaryFont,
          fontSize: '13px',
          lineHeight: '18px',
          color: resourceMidnightBlue,
          opacity: 0.5,
          '&:hover': {
            opacity: 1,
            fontWeight: 'bold',
          },
          '&$active': {
            fontWeight: 'bold !important',
          },
        },
        active: {
          opacity: 1,
        },
      },
    },
  });
};

export const resourceTheme = themeCreator({
  primaryColorHex: resourceRoyalPurple,
  secondaryColorHex: resourceRed,
});
export const acmeTheme = themeCreator({
  primaryColorHex: "#ea2d2c",
  secondaryColorHex: resourceRed,
});

