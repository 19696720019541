import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import cx from "classnames"
import { Typography } from "@material-ui/core"
import styles from "./header.module.scss"
import Hamburger from "../../assets/menu.svg"
import Cross from "../../assets/cross.svg"
import ArrowRight from "../../assets/arrow-right.svg"
import {
  getCEStatus,
  installCE,
  getButtonType,
  CTATypeEnum,
} from "../InstallCEButton/helpers"
import { trackLink, pathWithParams } from "../../utils"
import settings from "../../settings"

const renderCTA = installStatus => {
  const buttonType = getButtonType(installStatus)
  if (buttonType === CTATypeEnum.INSTALL) {
    return (
      <a
        onClick={installCE}
        alt="Install Resource Chrome Extension"
        className={cx(styles.tryCta)}
      >
        Try for free &nbsp;
        <ArrowRight />
      </a>
    )
  }
  if (buttonType === CTATypeEnum.DEMO) {
    return (
      <>
        {settings.IsInterviewGuide && (
          <a
            href="https://candidate.guide/walkthrough/"
            alt="Get Started"
            className={cx(styles.tryCta)}
          >
            Learn More{" "}
            <span role="img" aria-label="go">
              👉
            </span>
          </a>
        )}
      </>
    )
  }
  return (
    <a
      href="https://app.resource.io"
      className={cx(styles.tryCta)}
      onClick={e => trackLink(e, "Login CTA Click", "https://app.resource.io")}
    >
      Dashboard &nbsp;
      <ArrowRight />
    </a>
  )
}

class Header extends Component {
  constructor(props) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
    this.hideMobileMenu = this.hideMobileMenu.bind(this)
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this)
    this.activeLink = this.activeLink.bind(this)
    this.menuRef = React.createRef()
    this.state = {
      scrolling: false,
      showMobileMenu: false,
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
    window.addEventListener("mousedown", this.handlePageClick)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
    window.removeEventListener("mousedown", this.handlePageClick)
  }

  handleScroll(e) {
    const scrollPosition = e.srcElement.scrollingElement.scrollTop
    this.setState({ scrolling: scrollPosition > 0 })
  }

  handlePageClick(e) {
    const menu = this.menuRef.current
    if (!menu || menu.contains(e.target)) {
      return
    }
    this.setState({ showMobileMenu: false })
  }

  handleLinkClick(e) {
    e.preventDefault()
    if (e.target.className.includes("active")) return
    navigate(e.target.pathname)
    this.setState({ showMobileMenu: false })
  }

  toggleMobileMenu() {
    const { showMobileMenu } = this.state
    console.log("showMobileMenu:", showMobileMenu)
    this.setState({ showMobileMenu: !showMobileMenu })
  }

  hideMobileMenu() {
    this.setState({ showMobileMenu: false })
  }

  activeLink() {
    if (typeof window === "undefined") return null
    const {
      links: resourceLinks,
      mobileLinks: resourceMobileLinks,
      guideLinks,
      guideMobileLinks,
    } = this.props
    const links = settings.IsInterviewGuide ? guideLinks : resourceLinks || []
    const mobileLinks = settings.IsInterviewGuide
      ? guideMobileLinks
      : resourceMobileLinks
    return links
      .concat(mobileLinks)
      .find(link => window.location.pathname.includes(link.href))
  }

  render() {
    const {
      links: resourceLinks,
      mobileLinks: resourceMobileLinks,
      guideLinks,
      guideMobileLinks,
    } = this.props
    const links = settings.IsInterviewGuide ? guideLinks : resourceLinks || []
    const mobileLinks = settings.IsInterviewGuide
      ? guideMobileLinks
      : resourceMobileLinks
    const { inverted, bannerConfig, forceScrolling } = this.props
    const { scrolling, showMobileMenu } = this.state

    const logoSrc =
      inverted && !(scrolling || forceScrolling)
        ? "/images/brand/color/logo-white.svg"
        : "/images/brand/color/logo.svg"
    const active = this.activeLink()
    const Toggler = showMobileMenu ? Cross : Hamburger

    const installStatus = getCEStatus()

    return (
      <span>
        <span className={cx(styles.headerContainer)}>
          <header
            className={cx(styles.header, {
              [styles.scrolling]: scrolling || forceScrolling,
              [styles.menuShown]: showMobileMenu,
              [styles.inverted]: inverted,
            })}
          >
            {bannerConfig.showBanner && (
              <div className={styles.banner} style={{ color: "white" }}>
                {bannerConfig.primaryText}{" "}
                <a
                  style={{ color: "white", padding: "0px" }}
                  href={bannerConfig.linkUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {bannerConfig.linkText}
                </a>{" "}
                {bannerConfig.secondaryText}
              </div>
            )}
            <div className="container guide-header bold">
              <h1 className="m-0">
                {settings.IsInterviewGuide ? (
                  <Link to="/">
                    <img
                      className="m-0"
                      src="/images/brand/color/guides.svg"
                      alt="Resource"
                      title="Resource logo"
                    />
                  </Link>
                ) : (
                  <Link to="/">
                    <img
                      className="m-0"
                      src={logoSrc}
                      alt="Resource"
                      title="Resource logo"
                    />
                  </Link>
                )}
              </h1>
              <div ref={this.menuRef} style={{ outline: 0 }}>
                <Toggler
                  className={styles.hamburger}
                  onClick={this.toggleMobileMenu}
                />
                <div
                  className={cx(styles.menu, {
                    [styles.hidden]: !showMobileMenu,
                  })}
                >
                  <ul>
                    {links.map(link => (
                      <HeaderLink
                        key={link.title}
                        {...link}
                        active={active}
                        hideMobileMenu={this.hideMobileMenu}
                      />
                    ))}
                    <li>{renderCTA(installStatus)}</li>
                  </ul>

                  <ul className={cx(styles.mobileLinks)}>
                    {mobileLinks.map(link => (
                      <HeaderLink
                        key={link.title}
                        {...link}
                        active={active}
                        hideMobileMenu={this.hideMobileMenu}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </header>
        </span>
        <div className={styles.spacer} />
      </span>
    )
  }
}

const HeaderLink = ({ title, href, active, hideMobileMenu }) => {
  const isActive = active && active.title === title
  const className = cx({ [styles.active]: isActive })
  const onClick = e => {
    e.preventDefault()
    if (isActive) return
    navigate(href)
    hideMobileMenu()
  }
  const el = href.includes("://") ? (
    <a href={href} className={className}>
      {title}
    </a>
  ) : (
    <a onClick={onClick} href={href} className={className}>
      {title}
    </a>
  )
  return <li key={href}>{el}</li>
}

Header.propTypes = {
  inverted: PropTypes.bool,
  bannerConfig: PropTypes.shape({
    showBanner: PropTypes.bool,
    primaryText: PropTypes.string,
    secondaryText: PropTypes.string,
    linkText: PropTypes.string,
    linkUrl: PropTypes.string,
  }),
}

Header.defaultProps = {
  inverted: false,
  bannerConfig: {
    showBanner: false,
    primaryText: "",
    secondaryText: "",
    linkText: "",
    linkUrl: "",
  },
}

export default Header
