import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Input from '../Input/Input';
import styles from './forms.module.scss';
import { submitToNetlify } from './helpers';
import { identify, trackEvent, pageName } from '../../utils';

const formName = 'OutlookNotify';
const formProps = [ 'email'];

const initialState = {
  email: '',
  invalidFields: [],
  error: null,
  success: null,
  loading: false,
}

class OutlookForm extends Component {
  constructor(props) {
    super(props);
    this.invalid = this.invalid.bind(this);
    this.update = this.update.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      ...initialState,
    };
  }
  componentDidMount() {
    this.setState({pageName: pageName()})
  }
  onChange(obj) {
    this.setState(obj);
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.invalid()) return;
    this.setState({ loading: true });
    const { email, pageName } = this.state;
    const data = {
      email,
      pageName, 
      fullName: '',
      companyName: '',
      ats: '',
      teamSize: '',
      utmSource: '',
      utmCampaign: '',
      utmMedium: '',}
    identify(null, { email });
    submitToNetlify(({
      data,
      formName: 'DemoRequest'
    }), (error) => {
      if (error) {
        trackEvent('Outlook Notify Form Error', { email, error });
        return this.setState({ error, loading: false });
      }
      trackEvent('Outlook Notify Form Submission', { email });
      this.setState({ ...initialState, success: true });
    })
  }

  invalid() {
    const {
      email,
    } = this.state;
    if (!email) {
      return 'Please enter your email. 😟';
    }
    return false;
  }

  update(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  render() {
    const {
      email,
      error,
      success,
    } = this.state;

    return (
      <div>
        <div className={cx(styles.outlookFormHeader)}>
          <img src='/images/brand/color/icon.svg' alt="Resource" />
          <span role="img" aria-label="heart">❤️</span>
          <img src='/images/thirdparty/outlook.svg' alt="Outlook" />
        </div>
        <h2>Looking for Outlook support?</h2>

        <form className="m-0" name={formName} method="post" data-netlify="true" data-netlify-honeypot="bot-field">
          <input type="hidden" name="form-name" value={formName} />
          <div className={cx(styles.flex)}>
            <div className={cx(styles.inputWrap)}>
              <Input
                className={cx('w-100', 'm-2')}
                type="email"
                value={email}
                name='email'
                placeholder="Work Email"
                autocomplete='email'
                onChange={this.onChange}
                errorText="Email is required."
              />
            </div>
            <div>
              <button
                onClick={this.onSubmit}
                className={cx('btn', 'btn-sm', 'w-100', {
                disabled: this.invalid() && !success,
                'btn-green': success
              })}
                type="submit"
                alt={error || 'Submit form'}
              >{ success ? "Success! We'll be in touch." : 'Notify me' }
              </button>
            </div>

          </div>
        </form>
      </div>
    );
  }
}

OutlookForm.propTypes = {
};

OutlookForm.defaultProps = {
};

export default OutlookForm;
