import React from "react"
import Link from "gatsby-link"
import cx from "classnames"
import config from "../../../config/site"
import styles from "./footer.module.scss"
import InstallCEButton from "../InstallCEButton/InstallCEButton"
import PopupTrigger from "../Popup/PopupTrigger"
import OutlookForm from "../Forms/OutlookForm"
import { pageName } from "../../utils"
import settings from "../../settings"

const FooterCTA = ({
  ctaTitle,
  ctaSubtitle,
  showSubCTA,
  notifySubtitle,
  notifyCTA,
}) => (
  <div className={cx(styles.footerCTA)}>
    <div className="container">
      <div className={cx(styles.ctaImgWrap)}>
        <img src="/images/brand/color/icon.svg" title="Resource Icon" alt="" />
        <div className={cx("ctaText")}>
          <h1 className={cx("mb-2")}>{ctaTitle}</h1>
          <p>{ctaSubtitle}</p>
          {showSubCTA && (
            <p>
              <small>
                {notifySubtitle}&nbsp;
                {settings.IsInterviewGuide ? (
                  <a
                    href="https://offer.resource.io"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {notifyCTA}
                  </a>
                ) : (
                  <PopupTrigger
                    Trigger={<a>{notifyCTA}</a>}
                    popupProps={{ size: "sm" }}
                  >
                    <OutlookForm />
                  </PopupTrigger>
                )}
              </small>
            </p>
          )}
        </div>
      </div>
      <div className={cx(styles.ctaButtonWrap)}>
        {settings.IsInterviewGuide ? (
          <a
            href="https://candidate.guide/walkthrough/"
            alt="Get Started"
            className={cx(styles.tryCta)}
          >
            Learn More{" "}
            <span role="img" aria-label="go">
              👉
            </span>
          </a>
        ) : (
          <InstallCEButton className="btn-lg mb-1" />
        )}
        {/* <p className={cx('mb-0', 'pt-1')}><small>Want a demo? <Link to='/contact'>Contact us</Link>.</small></p> */}
      </div>
    </div>
  </div>
)

const Footer = ({
  className,
  guideLinks,
  links: resourceLinks,
  ...ctaProps
}) => {
  const actualCtaProps = settings.IsInterviewGuide
    ? {
        ctaTitle: ctaProps.guideCtaTitle,
        ctaSubtitle: ctaProps.guideCtaSubtitle,
        showSubCTA: ctaProps.guideShowSubCTA,
        notifySubtitle: ctaProps.guideNotifySubtitle,
        notifyCTA: ctaProps.guideNotifyCTA,
      }
    : ctaProps
  const links = settings.IsInterviewGuide ? guideLinks : resourceLinks

  return (
    <footer className={cx(styles.footer, className)}>
      {!pageName().includes("company") && !pageName().includes("contact") && (
        <FooterCTA {...actualCtaProps} />
      )}
      <div className={cx("container", styles.footerLinks)}>
        <div>
          <ul>
            {(links || []).map(link => {
              const el = link.href.includes("://") ? (
                <a href={link.href}>{link.title}</a>
              ) : (
                <Link to={link.href}>{link.title}</Link>
              )
              return <li key={link.href}>{el}</li>
            })}
          </ul>
          <p>
            <small>
              {config.copyright}
              &nbsp;&nbsp;
              <Link to="/terms">Terms Of Service</Link>
              &nbsp;&nbsp;
              <Link to="/privacy">Privacy Policy</Link>
            </small>
          </p>
        </div>
        <Link to="/">
          {settings.IsInterviewGuide ? (
            <img
              className="m-0"
              src="/images/brand/color/guide-logo-white.svg"
              alt="Guide"
              title="Guide logo"
            />
          ) : (
            <img
              src="/images/brand/color/logo-white.svg"
              alt="Resource Homepage"
            />
          )}
        </Link>
      </div>
    </footer>
  )
}

export default Footer
