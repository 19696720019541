import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import ChromeIcon from "../../assets/chrome.svg"
import GoogleIcon from "../../assets/google-color.svg"
import ArrowRight from "../../assets/arrow-right.svg"
import LookArrow from "../../assets/look-arrow.svg"
import { getButtonType, getCEStatus, installCE } from "./helpers"
import styles from "./install-button.module.scss"
import { trackEvent, trackPath, trackLink, pathWithParams } from "../../utils"

const buttonTypeProps = {
  DEMO: {
    mountEvent: "Schedule Demo CTA Mounted",
    id: "ScheduleDemoCTA",
    label: [<span>Learn more about Gem&nbsp;&nbsp;</span>, <ArrowRight />],
    href: "https://gem.com",
  },
  LOGIN: {
    mountEvent: "Login CTA Mounted",
    id: "LoginCTA",
    label: [<GoogleIcon />, <span>&nbsp;&nbsp;Login with Google</span>],
    color: "white",
    onClick: e => trackLink(e, "Login CTA Click", "https://app.resource.io"),
    href: "https://app.resource.io",
  },
  LAUNCH: {
    mountEvent: "Signed In CTA Mounted",
    id: "SignedInCTA",
    label: [<span>Resource dashboard &nbsp;&nbsp;</span>, <ArrowRight />],
    onClick: e =>
      trackLink(e, "Signed In CTA Click", "https://app.resource.io"),
    href: "https://app.resource.io",
  },
  INSTALL: {
    mountEvent: "Install Chrome Extension CTA Mounted",
    id: "InstallChromeExtensionCTA",
    label: [<ChromeIcon />, <span>&nbsp;&nbsp;One-click install</span>],
    onClick: installCE,
  },
  TRIAL: {
    mountEvent: "Try it Free",
    id: "TryItFree",
    label: [<ChromeIcon />, <span>&nbsp;&nbsp;Try it free</span>],
    onClick: installCE,
  },
}

const InstallCEButton = props => {
  const { style, className, type } = props

  const status = getCEStatus()
  let buttonType
  if (!type) {
    buttonType = getButtonType(status)
  } else {
    buttonType = type
  }
  const { mountEvent, label, color, ...buttonProps } =
    buttonTypeProps[buttonType] || {}
  if (!label) return <span />

  const CTAButton = (
    <a
      className={cx("btn", `btn-${color}`, className)}
      style={style}
      target="_blank"
      {...buttonProps}
    >
      {label}
    </a>
  )

  trackEvent(mountEvent)

  if (buttonType === "LOGIN") {
    return (
      <div className={cx(styles.loginCta)}>
        <div>
          <p>
            <strong>You're almost there!</strong> Sign in to get started.
          </p>
          <LookArrow className={styles.lookArrow} />
        </div>
        {CTAButton}
      </div>
    )
  }

  return CTAButton
}

InstallCEButton.propTypes = {
  style: PropTypes.shape(),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

InstallCEButton.defaultProps = {
  style: {},
  className: null,
}

export default InstallCEButton
