import { detect } from 'detect-browser';
import { trackEvent, isLoggedInUser } from '../../utils';

const isChrome = () => {
  const { name: browserName = '' } = detect() || {};
  return browserName.includes('chrome');
}

export const InstallStatusEnum = {
  NOT_CHROME: 'NOT_CHROME',
  NOT_INSTALLED: 'NOT_INSTALLED',
  IS_INSTALLED: 'IS_INSTALLED',
  IS_LOGGED_IN: 'IS_LOGGED_IN',
};

export const CTATypeEnum = {
  DEMO: 'DEMO',
  INSTALL: 'INSTALL',
  LOGIN: 'LOGIN',
  LAUNCH: 'LAUNCH',
};

export const getCEStatus = () => {
  if (isLoggedInUser()) {
    return InstallStatusEnum.IS_LOGGED_IN;
  }
  if (!isChrome() || typeof(chrome) === 'undefined') {
    return InstallStatusEnum.NOT_CHROME;
  }
  return chrome && chrome.app && chrome.app.isInstalled ? InstallStatusEnum.IS_INSTALLED
  : InstallStatusEnum.NOT_INSTALLED;
};

const onInstallClickFailure = (error) => {
  trackEvent('Install Chrome Extension Failure', {
    error
  });
}

const onInstallClickSuccess = () => {
  trackEvent('Install Chrome Extension Success');
}

export const installCE = () => {
  trackEvent('Install Chrome Extension CTA Click');
  chrome.webstore.install(null, onInstallClickSuccess, onInstallClickFailure);
}

// change when we want the chrome cta to go live.
export const getButtonType = (status) => {
  if (status === InstallStatusEnum.IS_LOGGED_IN) {
    return CTATypeEnum.LAUNCH;
  }
  if (status === InstallStatusEnum.IS_INSTALLED) {
    return CTATypeEnum.LOGIN;
  }
  return CTATypeEnum.DEMO;
}
